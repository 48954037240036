import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

function Page() {
  const [shouldDisplay, setShouldDisplay] = useState(false)

  useEffect(() => {
    if (typeof window !== undefined) {
      setShouldDisplay(true)
    }
  }, [])

  return (
    <>
      {shouldDisplay && (
        <Helmet>
          <script src="https://dvhvhmfie306r.cloudfront.net/Prod/livestreamingshopping?id=2a7d54ec-ce8e-438c-b8ae-010e91a23f93&#38;account=miess&#38;inactiveSidebarProducts=true&#38;inactiveProductsCarousel=false&#38;inactivateChat=true&#38;inactivateLike=true&#38;inactivateViewers=true&#38;isInfinite=true&#38;time=10&#38;pdp=true&#38;kuikpay=false&#38;quickView=true" />
        </Helmet>
      )}

      <div id="live-shopping-app" />
    </>
  )
}

export default Page
